/* You can add global styles to this file, and also import other style files */
// @import "../libs/vg-common/styles/styles.scss";

@font-face {
  font-family: barlow-regular;
  src: url("./assets/fonts/BARLOW/Barlow-Regular.ttf");
}
@font-face {
  font-family: barlow-semibold;
  src: url("./assets/fonts/BARLOW/Barlow-SemiBold.ttf");
}
@font-face {
  font-family: barlow-medium;
  src: url("./assets/fonts/BARLOW/Barlow-Medium.ttf");
}
@font-face {
  font-family: barlow-black;
  src: url("./assets/fonts/BARLOW/Barlow-Black.ttf");
}

@font-face {
  font-family: barlow-bold;
  src: url("./assets/fonts/BARLOW/Barlow-Bold.ttf");
}
@font-face {
  font-family: barlow-italic;
  src: url("./assets/fonts/BARLOW/Barlow-Italic.ttf");
}
@font-face {
  font-family: barlow-extra-bold;
  src: url("./assets/fonts/BARLOW/Barlow-ExtraBold.ttf");
}

@font-face {
  font-family: didot-bold-italic;
  src: url("./assets/fonts/DIDOT/Didot Bold Italic.ttf");
}

@import "./style.css";

// $custom-file-text: ();

html,
body {
  // background-color: black;
  font-family: barlow-regular;
  color: white;
}

.minHeightFull {
  min-height: 90vh;
  // background-color: red;
}

.pointer {
  cursor: pointer;
}

/* Custom Theme Vayvo */
header#masthead-pro {
  background: #000;
}

body #logo-pro img {
  width: 265px !important;
  // padding-top: 12px !important;
  // padding-bottom: 12px !important;
  margin: 0 36px 0 36px !important;
}

ul#mobile-menu-pro li .sub-collapser {
  display: none !important;
}

ul#mobile-menu-pro li .sub-collapser-custom {
  background: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  text-align: center;
  z-index: 999;
  cursor: pointer;
  height: 57px;
  // content: "\f107";
  // font-family: "Font Awesome 5 Free";
  // font-size: 13px;
  // line-height: 57px;
  // background: rgba(255, 255, 255, 0.09);
  // color: red;
}

ul#mobile-menu-pro.collapsed li .sub-collapser-custom::before {
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 13px;
  border: none;
  content: "\f107";
  text-align: center;
  line-height: 57px;
  width: 50px;
  background: rgba(255, 255, 255, 0.09);
}

ul#mobile-menu-pro.collapsed li .sub-collapser-custom.expanded {
  transform: rotate(180deg);
}

// ul#mobile-menu-pro li .sub-collapser > i {
//   display: none;
// }

// ul#mobile-menu-pro i {
//   width: 32px;
//   margin-bottom: -10px;
//   font-size: 16px;
//   text-align: center;
// }

.artigos-white {
  background-color: white;
  color: black;
  padding: 35px 70px;
}
.artigos-white h3 {
  margin-top: 0;
  color: black;
}
.artigo-white {
  padding: 0 40px;
}

.artigos-wide-white {
  background-color: white;
  color: black;
  padding: 0;
  margin: 0;
}

.artigos-yellow {
  background-color: yellow;
  color: black;
  padding: 35px 70px;
}
.artigos-yellow h3 {
  margin-top: 0;
  color: black;
}

// .linkRed {
//   color: #83301b;
//   font-weight: bold;
// }

// .linkRed:hover {
//   color: darkred;
//   font-weight: bold;
// }

.sliderTextBottomLeft {
  margin-left: 3vw !important;
  text-align: left !important;
  font-size: 39px !important;
  color: white !important;
  font-weight: normal !important;
  display: grid !important;
  line-height: 1.2 !important;
  font-style: normal !important;
}

.cpl-5 {
  padding-left: 70px !important;
}

.stb1 {
  font-size: 14px;
}
.stb2 {
  font-size: 22px;
}
.stb3 {
  font-size: 38px;
}

.fontSize18 {
  font-size: 18px;
}
// .artigo {
//   font-family: "barlow-regular";
//   color: black;
//   font-size: 19px;
// }

// .artigoTitulo {
//   font-family: "barlow-bold";
//   font-size: 24px;
// }

.artigoTitulo {
  font-family: "didot-bold-italic";
  font-size: 32px;
}

.artigoTituloBold {
  text-transform: uppercase;
  font-family: "barlow-black";
  margin-bottom: 10px;
}

// .artigoTituloDidot {
//   font-family: "didot-bold-italic";
//   font-size: 42px;
// }

// .artigoTituloBold {
//   font-family: "barlow-extra-bold";
//   font-size: 19px;
//   text-transform: uppercase;
//   font-weight: bolder;
//   margin-bottom: 10px;
// }

.artigoDestaque {
  font-family: "barlow-semibold";
  color: black;
  font-size: 16px; /*21px;*/
  margin-bottom: 40px;
  line-height: 1.5rem;
}

.artigoDestaqueRed {
  color: rgb(179, 46, 21) !important;
  font-size: 20px;
  font-family: "barlow-bold";
  // font-weight: bold;
}

.artigoDestaqueTitulo {
  font-weight: bold;
  font-style: italic;
  font-size: 18px;
  margin-bottom: 20px;
}

.artigoBody {
  font-family: "barlow-medium";
  font-size: 14px;
}

.artigoBigAspas {
  font-size: 100px;
  font-style: italic;
  line-height: 10px;
}

.artigoAuditionsLeft {
  font-size: 32px;
  font-style: italic;
  font-weight: bold;
  line-height: 1.2;
}

.artigoAuditionsRight1 {
  font-size: 16px;
  font-weight: bold;
}

.artigoAuditionsRight2 {
  font-size: 22px;
  font-weight: bold;
}

.fichaTecnicaImage {
  height: 100px;
}

.colorWhite {
  color: white;
  font-size: 18px;
}

.bgRed {
  background-color: #b32e15;
}
.barlowRegular {
  font-family: "barlow-regular" !important;
}
.barlowBold {
  font-family: "barlow-bold";
}

.nav-menu {
  position: fixed;
  left: 39%;
}

.artigos-col-2 {
  column-count: 2;
  column-gap: 40px;
}

nav#site-navigation-pro .sf-menu a {
  font-size: 1.05rem;
}

.btn.cBtnLang,
span.cBtnLang {
  // border: 2px solid #fff;
  // background: #000;
  // padding: 4px 12px !important;
  padding: 0 6px 0 6px;
  color: #ccc;
  background: none;
  border: none;
  font-family: barlow-regular;
  font-size: 19px;
  border-radius: 0;
}

.cBtnLang.active {
  border-bottom: 1px solid yellow;
  background: none;
}

.nopointer {
  cursor: auto;
}

#video-embedded-container {
  max-width: 90%;
  //padding: 70px 20px 180px 20px;
  // text-align: center;
  // margin: 0 auto;
  // max-width: 900px;
  // position: relative;
  // height: auto;
}

h1,
h2,
h3 {
  margin-top: 30px;
  margin-bottom: 20px;
}

// h1.tit1 {
//     margin-top: 30px;
//     margin-bottom: 20px;
// }

p.txt1 {
  font-size: 20px;
  margin-bottom: 1rem;
  line-height: 1.4;
}

.titA {
  font-size: 1.5rem;
}

footer {
  background-color: black;
}

.footer-menu {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 0;
  padding-right: 0;
  font-size: 18px;
  color: rgba(181, 187, 212, 0.44);
  text-align: center;
}

.footer-menu ul {
  list-style-type: none;
  padding: 0;
}

.footer-menu a:link {
  color: white;
  text-decoration: none;
}

.footer-menu a:hover,
.footer-menu a:visited {
  color: #22b2ee;
}

.copyright-text-pro {
  color: white;
  padding: 0;
}

footer#footer-pro ul.social-icons-pro {
  padding: 15px 0px;
}

.btnSubscribe {
  border: 2px solid #fff;
  background: #000;
  border-radius: 0;
  font-weight: bold;
}

.form-controlSubscribe {
  border-radius: 0;
}

// /* SLIDER Carousel custom */ //
// .flexslider .slidesCarousel { //isto centrava os conteúdos mas em vez imagens passavamos a div com background e comentei!
//     zoom: 1;
//     display: -ms-flexbox;
//     display: -webkit-flex;
//     display: flex;
//     -ms-flex-align: center;
//     -webkit-align-items: center;
//     -webkit-box-align: center;
//     align-items: center;
// }

.flexslider .slides > li.liSlidesCarousel {
  border: 2px solid #08070e;
}

.flexslider .slides > li.liSlidesCarousel:hover,
.bconteudo:hover {
  border: 2px solid #006699;
}

.bconteudo::before {
  // content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.25);
}

.bconteudo {
  // background: url('assets/images/CantigosDoFuturo.jpg') no-repeat;
  // background: url('assets/images/AVidaETeatro.png') no-repeat;

  position: relative;
  cursor: pointer;
  min-height: 250px;
  vertical-align: bottom;
}

.bconteudo > div {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

// .flexslider .slides > li.liSlidesCarousel > div {
//     // background: url('assets/images/CantigosDoFuturo.jpg') no-repeat;
//     // background: url('assets/images/AVidaETeatro.png') no-repeat;

//     position: relative; cursor: pointer;
//     min-height: 300px;
//     vertical-align: bottom;
// }

.flexslider .slides > li.liSlidesCarousel > a > div {
  // background: url('assets/images/CantigosDoFuturo.jpg') no-repeat;
  // background: url('assets/images/AVidaETeatro.png') no-repeat;

  position: relative;
  cursor: pointer;
  min-height: 250px;
  vertical-align: bottom;
}

.flexslider .slides > li.liSlidesCarousel > a > div > div {
  position: absolute;
  bottom: 40px;
  left: 10px;
}

.fs15 {
  font-size: 15px;
}
.fs20 {
  font-size: 20px;
}
.fs18 {
  font-size: 18px;
}
.fs22 {
  font-size: 22px;
}

// /* FIM SLIDER Carousel custom */ //

// h2.tit2 {
//     margin-bottom: 20px;
//     margin-top: 30px;
// }

a:link {
  text-decoration: none;
}

a.lnk:link,
a.lnk:visited {
  color: #fff;
  text-decoration: none;
}

a.lnk:hover {
  color: #006699;
  text-decoration: none;
}

a.linkRed:link,
a.linkRed:visited,
footer a:link,
footer a:visited {
  color: white !important;
  text-decoration: none;
}

a.linkRed:hover,
footer a:hover {
  color: #83301b !important;
  text-decoration: none;
}

a.linkRedOnWhite:link,
a.linkRedOnWhite:visited {
  color: #83301b !important;
  text-decoration: none;
}

a.linkRedOnWhite:hover {
  color: #a53c21 !important;
  text-decoration: none;
}


a.linkNone:link,
a.linkNone:visited,
footer a:link,
footer a:visited {
  // color: white !important;
  text-decoration: none;
}

a.linkNone:hover,
footer a:hover {
  // color: #83301b !important;
  text-decoration: none;
}

a.linkNone:link,
a.linkNone:visited {
  // color: #83301b !important;
  text-decoration: none;
}

a.linkNone:hover {
  // color: #a53c21 !important;
  text-decoration: none;
}

@keyframes shrink {
  0% {
    background-size: auto 100%;
  }
  100% {
    background-size: auto 105%;
  }
}

#video-cast-display-post-section {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  margin-top: 5px;
  padding-top: 40px;
}

#video-more-like-this-details-section {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  margin-top: 5px;
  padding-top: 40px;
}

#video-cast-display-post-section h3,
#video-more-like-this-details-section h3#more-videos-heading {
  font-weight: 500;
  font-size: 26px;
}

ul.vayvo-video-cast-list {
  list-style: none;
  margin: 20px 0px 15px 0px;
  padding: 0px;
  width: 100%;
  overflow: hidden;
}

ul.vayvo-video-cast-list li {
  list-style: none;
  margin: 0px 0px 20px 0px;
  display: block;
  float: left;
  width: calc(33.2% - 22px);
  padding-right: 22px;
}

.vayvo-video-cast-photo {
  float: left;
  width: 45px;
  height: 45px;
  border-radius: 30px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.1);
  margin-right: 14px;
}

ul.vayvo-video-cast-list li h6 {
  font-weight: 400;
  position: relative;
  font-size: 16px;
  padding-top: 6px;
}

ul.vayvo-video-cast-list li h6:hover {
  color: #006699;
}

img.vayvo-video-cast-image-detail {
  width: 140px;
  //min-height: 80px;
  // height: 80px;
  border-radius: 6px;
  border: 1px solid #555;
}

/* FIM Custom Theme Vayvo */

.menu-overlapped {
  position: absolute;
  width: 100%;
  z-index: 10;
}
.destaque-menu {
  background-color: #908b35;
  color: black !important;
  // padding-left: 10px !important;
  // padding-right: 10px !important;
  padding: 5px 14px !important;
  // margin-left: 50px;
  margin-right: 6vw;
  font-weight: bold;
  font-family: barlow-semibold, sans-serif;
  font-size: 1.05em;
}
.destaque-menu:hover,
.destaque-menu.active {
  // border-bottom: none !important;
  border-bottom: 1px solid yellow;
}
.destaque-menu:before {
  /* background-color: transparent !important; */
}

/* https://www.html.am/html-codes/marquees/html-scrolling-text.cfm */
.scroll-right {
  height: 25px;
  overflow: hidden;
  position: relative;
  // background: yellow;
  //   border: 1px solid orange;
}
.scroll-right p {
  font-family: "barlow-semibold";
  position: absolute;
  // background-color: #ada8a7;
  // color: black;
  // word-spacing: 30px;
  // word-spacing: 15px;
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 25px;
  text-align: left;
}

.scroll-right p.p1 {
  /* Starting position */
  transform: translateX(100%);
  /* Apply animation to this element */
  animation: scroll-right1 20s linear infinite;
  // &:hover {
  //   animation-play-state: paused;
  // }
}

.scroll-right p.p2 {
  /* Starting position */
  transform: translateX(100%);
  /* Apply animation to this element */
  animation: scroll-right2 20s linear infinite;
  // &:hover {
  //   animation-play-state: paused;
  // }
}

.pause {
  animation-play-state: paused !important;
}

.scroll-right.st1 {
  background-color: #ada8a7;
  // color: black;
}
.scroll-right.st2 {
  background-color: #83301b;
  // color: white;
  // color: #9b963b;
}
// bg #83301b
// color #9b963b

.scroll-right p a {
  font-family: "barlow-bold";
  // background-color: red;
  // color: yellow;
  // color: black;
  // margin: 0;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  font-weight: bold;
}

.scroll-right.st1 a {
  color: black;
}
.scroll-right.st2 a {
  color: white;
  // color: #9b963b;
}

/* Move it (define the animation) */
@keyframes scroll-right {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes scroll-right1 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes scroll-right2 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}
/* fim */

.progression-studios-slider-caption-width h2 {
  text-align: center;
  // color: #908b35;
  // color: #bdb445;
  color: #ffed00;
  // font-family: "didot-italic";
  font-family: "didot-bold-italic";
  font-size: 60px;
  font-style: italic;
}

.custom-navigation .flex-control-nav li {
  display: block;
  margin-top: 12px;
}

.custom-navigation .flex-control-nav {
  width: auto;
  right: 10px;
  top: 30%;
}

.custom-navigation .flex-direction-nav .flex-prev {
  left: 48%;
}

.custom-navigation .flex-direction-nav .flex-next {
  left: 48%;
}

.custom-navigation .flex-direction-nav .flex-nav-prev a {
  top: 16%;
}

.custom-navigation .flex-direction-nav .flex-nav-next a {
  top: 90%;
}

.flexslider:hover .custom-navigation .flex-direction-nav .flex-nav-prev a {
  top: 12%;
}

.flexslider:hover .custom-navigation .flex-direction-nav .flex-nav-next a {
  top: 96%;
}

.custom-navigation .flex-direction-nav a.flex-next:before {
  content: "\f078";
  position: relative;
  right: -1px;
  font-size: 40px;
}

.custom-navigation .flex-direction-nav a.flex-prev:before {
  content: "\f077";
  position: relative;
  right: -1px;
  font-size: 40px;
}

.progression-studios-slider-image-background {
  background-position: top center;
}

.mtc-5 {
  margin-top: 3rem !important;
}

.barrinhaLeft {
  border-left: 2px solid gray;
}

@media only screen and (max-width: 1420px) {
  .destaque-menu {
    // margin-left: 5px !important;
    margin-right: 3vw !important;
  }
  .nav-menu {
    left: 32%;
  }
}

@media only screen and (max-width: 1193px) {
  .destaque-menu {
    margin-left: 0 !important;
  }
  body #logo-pro img {
    width: 210px !important;
    margin: 5px 16px 0 16px !important;
  }
}

@media only screen and (max-width: 920px) {
  .artigos-col-2 {
    column-count: 1;
  }
  .artigos-white {
    padding: 35px 15px;
  }
}

@media only screen and (max-width: 768px) {
  .barrinhaLeft {
    border-left: none;
  }
  .artigos-yellow {
    padding: 35px 20px;
  }
}

@media only screen and (max-width: 600px) {
  .destaque-menu {
    display: none;
  }
  .mtc-5 {
    margin-top: 2rem !important;
  }
  .progression-studios-slider-caption-width h2 {
    font-size: 50px;
  }
  .artigoBigAspas {
    font-size: 60px;
    line-height: 5px;
    max-height: 10px;
  }
}

@media only screen and (max-width: 425px) {
  body #logo-pro img {
    width: 200px !important;
    margin: 10px 4px 0 4px !important;
  }

  .artigos-white {
    padding: 20px 10px;
  }
  .mtc-5 {
    margin-top: 1rem !important;
  }
  .progression-studios-slider-caption-width h2 {
    font-size: 40px;
  }

}
